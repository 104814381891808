<template>
	<div>
		<!--Blog Content-->
		<div v-if="article" class="page-container mx-auto lg:px-8 max-w-screen-lg mb-28">
			<div class="text-center max-w-4xl mx-auto pt-20 pb-8">
				<span class="text-theme font-bold mb-3 block">
					{{ countReadingTime(article.data.attributes.content) }} Min. Reading / Published at {{ convertDate(article.data.attributes.createdAt) }}
				</span>
				<h1 class="lg:text-4xl text-2xl xl:leading-2xl lg:leading-2xl leading-tight xl:mb-12 lg:mb-10 mb-6 relative tracking-tight font-black">
					{{ article.data.attributes.title }}
				</h1>
				<img :src="'https://blog.optiomat.com' + article.data.attributes.cover.data.attributes.url" alt="" class="rounded-xl mb-6">
			</div>

			<div class="blog-content font-semibold text-xl leading-relaxed max-w-3xl mx-auto" v-html="convertContent(article.data.attributes.content)"></div>
		</div>

		<!--Latest Articles-->
		<header class="bg-secondary mb-28">
			<figure class="bg-wave-secondary bg-w-full bg-no-repeat bg-top relative lg:-top-6 -top-3 h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>

			<div class="page-container mx-auto lg:flex lg:text-left items-center text-center justify-between gap-x-12">
				<div class="lg:w-1/2 lg:mb-0 mb-12">

					<!--Page Title-->
					<h1 class="2xl:text-6xl lg:text-5xl text-4xl xl:leading-2xl lg:leading-3xl leading-tight xl:mb-8 lg:mb-6 mb-4 relative tracking-tight font-black">
						Streamlining <span class="text-theme">ESOP</span> Plans for Founders
					</h1>
					<h2 class="2xl:text-2xl text-xl font-bold mb-12">
						Insights and Automation Strategies. Let’s tell stories about world of ESOPs.
					</h2>
					<router-link :to="{name: 'Blog'}" class="font-extrabold text-theme bg-white rounded-xl whitespace-nowrap py-3 px-5 hover:bg-theme hover:text-white transition-all">
						Visit Blog
					</router-link>
				</div>

				<!--Hero Image-->
				<img class="relative lg:w-1/2 rounded-lg max-w-[700px] mx-auto z-10" style="margin-bottom: -120px" src="/images/blog/blog-hero.png" alt="">
			</div>

			<!--Wave BG-->
			<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1"></figure>
		</header>

		<!--Latest Articles-->
		<BlogListOfArticles v-if="articles" :articles="articles">
			Read More Articles:
		</BlogListOfArticles>
	</div>
</template>

<script>
	import BlogListOfArticles from "../components/blog/BlogListOfArticles";
	import BlogFeatured from "../components/blog/BlogFeatured";
	import BlogHeader from "../components/blog/BlogHeader";
	import axios from "axios";
	import { marked } from 'marked';

	export default {
		name: 'BlogArticle',
		components: {
			BlogListOfArticles,
			BlogFeatured,
			BlogHeader,
		},
		data() {
			return {
				article: null,
				articles: null,
			}
		},
		methods: {
			convertDate(datetime) {
				let date = new Date(datetime);

				let options = {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					timeZone: 'UTC'
				};

				return date.toLocaleString('en-US', options)
			},
			countReadingTime(content) {
				let words = content.split(" ");

				let readingSpeed = 200;
				let readingTime = words.length / readingSpeed;

				return Math.ceil(readingTime)
			},
			convertContent(content) {
				return marked(content)
			}
		},
		mounted() {
			axios.get(`https://blog.optiomat.com/api/blogs/${this.$route.params.article}?populate=*`)
				.then((response) => {
					this.article = response.data

					axios.get(`https://blog.optiomat.com/api/blogs?populate=*`)
						.then((response) => {
							this.articles = response.data.data.filter(thisArticle => thisArticle.attributes.title !== this.article.data.attributes.title)
						})
						.catch((error) => alert('Sadly, there is an error :(('))
				})
				.catch((error) => alert('Sadly, there is an error :(('))
		}
	}
</script>

<style lang="scss">

	.blog-content {
		p {
			margin-bottom: 20px;
		}

		ul {
			list-style: disc;
			padding-left: 40px;
			margin-bottom: 20px;
		}

		a {
			color: #5151E7;

			&:hover {
				text-decoration: underline;
			}
		}
	}
</style>
